import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FreightcomparisionService {
   headerHide = new BehaviorSubject<any>('');
  constructor(private _httpclient:HttpClient) { }

  getComparision(Id): Observable<any>{
   return this._httpclient.get(`api/transporter-inward/compare-invoice?transportinvoiceno=${Id}`);
  }
}
