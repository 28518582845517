<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
  <!-- Navbar brand -->
  <div class="navbar-header">
    <ul class="nav navbar-nav flex-row">
      <li class="nav-item mr-auto">
        <!-- App Branding -->
        <a class="navbar-brand" (click)="ridirectPage()">
          <span class="brand-logo" style="padding: 7px; width: 258px; height: 65px; margin-right: 10px;">
            <img src="../../../assets/images/logo/godrej_logo.gif" alt="brand-logo" />
          </span>
        </a>
      </li>
    </ul>
  </div>
  <!--/ Navbar brand -->
</div>

<div class="navbar-header" style="flex: 0 0 60%;">
  <div class="d-flex">
    <div *ngIf="headerMenu">
      <a class="navbar-brand" routerLink="dashboard/home">
        <div class="nav navbar-nav flex-row header_Name">
          <span class="brand-logo textcolor">
            GCPL Dashboard
          </span>
        </div>
      </a>

      <a class="navbar-brand" routerLink="apps/reports/gcpl-InvoiceReports">
        <div class="nav navbar-nav flex-row header_Name">
          <span class="brand-logo textcolor">
            Reports
          </span>
        </div>
      </a>
      <a class="navbar-brand" *ngIf="userRollId == 1"  routerLink="apps/admins/admin">
        <div class="nav navbar-nav flex-row header_Name">
          <span class="brand-logo textcolor">
            Admin Master
          </span>
        </div>
      </a>
    </div>
    <div *ngIf="tranporterHeaderMenu">
      <a class="navbar-brand" routerLink="apps/transporter/dashboard/tranporter/Invoice_details">
        <div class="nav navbar-nav flex-row header_Name">
          <span class="brand-logo textcolor">
            Transporter Dashboard
          </span>
        </div>
      </a>
    </div>
    <div *ngIf="domensticHeaderMenu">
      <a class="navbar-brand" routerLink="apps/domestic-transporter/dashboard/Invoice_Detail">
        <div class="nav navbar-nav flex-row header_Name">
          <span class="brand-logo textcolor">
            Domestic Transporter Dashboard
          </span>
        </div>
      </a>
    </div>
    <div *ngIf="RegionalManagerReport">
      <a class="navbar-brand" routerLink="apps/reports/gcpl-InvoiceReports">
        <div class="nav navbar-nav flex-row header_Name">
          <span class="brand-logo textcolor">
            Reports
          </span>
        </div>
      </a>
    </div>
    <div *ngIf="CategoryManagerReport">
      <a class="navbar-brand" routerLink="apps/reports/gcpl-InvoiceReports">
        <div class="nav navbar-nav flex-row header_Name">
          <span class="brand-logo textcolor">
            Reports
          </span>
        </div>
      </a>
    </div>
    <div *ngIf="LogisticManagerReport">
      <a class="navbar-brand" routerLink="apps/reports/gcpl-InvoiceReports">
        <div class="nav navbar-nav flex-row header_Name">
          <span class="brand-logo textcolor">
            Reports
          </span>
        </div>
      </a>
    </div>
  </div>
</div>

<div class="navbar-container d-flex content">
  <ul class="nav navbar-nav align-items-center ml-auto">
    <!-- Language selection -->
    <!-- <li ngbDropdown class="nav-item dropdown dropdown-language">
      <a class="nav-link dropdown-toggle" id="dropdown-flag" ngbDropdownToggle>
        <i class="flag-icon flag-icon-{{ languageOptions[_translateService.currentLang].flag }}"></i
        ><span class="selected-language">{{ languageOptions[_translateService.currentLang].title }}</span></a
      >
      <div ngbDropdownMenu aria-labelledby="dropdown-flag">
        <a *ngFor="let lang of _translateService.getLangs()" ngbDropdownItem (click)="setLanguage(lang)">
          <i class="flag-icon flag-icon-{{ languageOptions[lang].flag }}"></i> {{ languageOptions[lang].title }}
        </a>
      </div>
    </li> -->
    <!--/ Language selection -->

    <!-- Toggle skin -->
    <!-- <li class="nav-item d-none d-lg-block">
      <a class="nav-link nav-link-style btn" (click)="toggleDarkSkin()">
        <span [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'" class="ficon font-medium-5 feather"></span>
      </a>
    </li> -->
    <!--/ Toggle skin -->

    <!-- Search -->
    <!-- <app-navbar-search></app-navbar-search> -->
    <!--/ Search -->

    <!-- Cart -->
    <!-- <app-navbar-cart></app-navbar-cart> -->
    <!--/ Cart -->

    <!-- Notification -->
    <!-- <app-navbar-notification></app-navbar-notification> -->
    <!--/ Notification -->

    <!-- User Dropdown -->
    <li ngbDropdown class="nav-item dropdown-user">
      <a class="nav-link dropdown-toggle dropdown-user-link" id="dropdown-user" ngbDropdownToggle
        id="navbarUserDropdown" aria-haspopup="true" aria-expanded="false">
        <ng-container *ngIf="this.currentUser">
          <div class="user-nav d-sm-flex d-none">
            <span class="user-name font-weight-bolder">Logged in : <span style="color: royalblue;">{{
                this.currentUser?.username | uppercase }}</span></span><span class="user-status font-weight-bolder">Role
              : <span style="color: royalblue;">{{ this.currentUser?.role | titlecase }}</span></span>
          </div>
          <span class="avatar"><img class="round" src="assets/images/logo/default.jpg" alt="avatar" height="40"
              width="40" /><span class="avatar-status-online"></span></span>
        </ng-container>
      </a>
      <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right">
        <!-- <a ngbDropdownItem [routerLink]="['/pages/profile']"
          ><span [data-feather]="'user'" [class]="'mr-50'"></span> Profile</a
        ><a ngbDropdownItem [routerLink]="['/apps/email']"
          ><span [data-feather]="'mail'" [class]="'mr-50'"></span> Inbox</a
        ><a ngbDropdownItem [routerLink]="['/apps/todo']"
          ><span [data-feather]="'check-square'" [class]="'mr-50'"></span> Task</a
        ><a ngbDropdownItem [routerLink]="['/apps/chat']"
          ><span [data-feather]="'message-square'" [class]="'mr-50'"></span> Chats</a
        >
        <div class="dropdown-divider"></div>
        <a ngbDropdownItem [routerLink]="['/pages/account-settings']"
          ><span [data-feather]="'settings'" [class]="'mr-50'"></span> Settings</a
        ><a ngbDropdownItem [routerLink]="['/pages/pricing']"
          ><span [data-feather]="'credit-card'" [class]="'mr-50'"></span> Pricing</a
        > -->
        <a ngbDropdownItem [routerLink]="['/pages/authentication/forgot-password-v1']">Change Password</a>
        <a ngbDropdownItem (click)="logout(this.currentUser?.id)"><span [data-feather]="'power'"
            [class]="'mr-50'"></span> Logout</a>
      </div>
    </li>
    <!--/ User Dropdown -->
  </ul>
</div>